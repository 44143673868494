/* eslint-disable react/display-name */
import React, { useEffect, useState, useContext } from 'react';
import { navigate } from 'gatsby';
import { Progress } from 'antd';
import { RouteComponentProps, useLocation } from '@reach/router';
import TableComponent from '../../Shared/Table/TableComponent';
import * as styles from '../../../styles/admin/CourseReport.module.scss';
import AdminContext from '../../../context/Admin/AdminContext';
import BarChart from '../../Shared/BarChart';
import { fetchMetrics } from '../Services/MetricsApiService';
import { Course } from '../../../models';
import {
  ChartData, CourseFunnelAttributes, CourseProgress, CourseRankingAttributes,
  CourseRegistrationStats, MergedCourseRegistrationStats,
} from '../../../types/courseReport';
import { fetchCourseById } from './PerformanceService';

interface Props extends RouteComponentProps {
  courseId?: string,
}

const CourseReport:React.FC<Props> = ({ courseId }) => {
  const { profileInfo } = useContext(AdminContext);
  const [tableData, setTableData] = useState<CourseProgress[]>([] as CourseProgress[]);
  const [courseSummary, setCourseSummary] = useState<CourseRankingAttributes | null>(null);
  const [chartData, setChartData] = useState<ChartData | null>(null);
  const [courseData, setCourseData] = useState<Course | null>(null);
  const [loader, setLoader] = useState(true);

  const location = useLocation() as any;

  const isPrivateCourse = location?.state?.courseAccessType === 'PRIVATE';

  const fetchCourseRegistrations = async (enrolledUsersDetails:MergedCourseRegistrationStats[]) => {
    const courseProgress:CourseProgress[] = [];
    enrolledUsersDetails.forEach((userProgress) => {
      const attributes = userProgress?.attributes;
      const lessonsWatched = attributes?.videoTasksCompleted;
      const totalLessons = attributes?.videoTasks;
      const assignmentsCompleted = attributes?.assignmentTasksCompleted
     + attributes?.finalAssignmentTasksCompleted;
      const totalAssignments = attributes?.assignmentTasks
     + attributes?.finalAssignmentTasks;
      const totalTasksCompleted = lessonsWatched + assignmentsCompleted;
      const totalTasks = totalLessons + totalAssignments;
      const completion = totalTasks > 0 ? Math.round((totalTasksCompleted / totalTasks) * 100) : 0;
      courseProgress.push({
        key: userProgress?.userId,
        name: userProgress?.userName,
        classes: `${lessonsWatched}/${totalLessons}`,
        assignments: `${assignmentsCompleted}/${totalAssignments}`,
        completion,
      });
    });
    setTableData(courseProgress);
    setLoader(false);
  };

  const prepareChartData = (chartAttributes:CourseFunnelAttributes) => {
    const enrolledCourses = chartAttributes?.usersEnrolled;
    const watchingLesson = chartAttributes?.usersWatchingLesson;
    const completedAssignments = chartAttributes?.usersCompletedAssignment;
    const completedCourse = chartAttributes?.usersCompletedCourse;
    const formattedData = [
      enrolledCourses ? Math.floor((enrolledCourses / enrolledCourses) * 100).toFixed(0) : '0',
      enrolledCourses ? Math.floor((watchingLesson / enrolledCourses) * 100).toFixed(0) : '0',
      enrolledCourses ? Math.floor((completedAssignments / enrolledCourses) * 100).toFixed(0) : '0',
      enrolledCourses ? Math.floor((completedCourse / enrolledCourses) * 100).toFixed(0) : '0',
    ];
    const data = {
      labels: [
        [enrolledCourses, 'Enrolled'],
        [watchingLesson, 'Watching lesson'],
        [completedAssignments, 'Taking assignment'],
        [completedCourse, 'Completed the course'],
      ],
      datasets: [{
        data: formattedData,
        backgroundColor: 'rgba(156, 106, 249, 0.5)',
        borderWidth: 1,
      }],
    };
    setChartData(data);
  };

  const mergeResponseData = (response:CourseRegistrationStats):MergedCourseRegistrationStats[] => {
    if (response?.data?.length > 0) {
      const dataMap = new Map();
      const includedData:MergedCourseRegistrationStats[] = [];
      response?.data.forEach((data) => {
        const userId = data?.relationships?.user?.data?.id;
        dataMap.set(userId, data);
      });
      response?.included.forEach((included) => {
        const userId = included?.id;
        const dataAttributes = dataMap.get(userId);
        if (dataAttributes) {
          includedData.push({
            attributes: dataAttributes?.attributes,
            userId,
            userName: included?.attributes?.name,
            lastActive: included?.attributes?.lastActive,
          });
        }
      });
      return includedData;
    }
    return [];
  };

  const fetchCourseReportForEnrolledUsers = async () => {
    try {
      const enterpriseId = profileInfo?.enterpriseID || '';
      const path = '/courseregistrationstats';
      const response = await fetchMetrics(path, {
        enterpriseId,
        courseId,
      });
      const mergedResponse = mergeResponseData(response);
      fetchCourseRegistrations(mergedResponse);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCourseRanking = async () => {
    try {
      const enterpriseId = profileInfo?.enterpriseID || '';
      const path = `/contentperformanceranking/${courseId}`;
      const response = await fetchMetrics(path, {
        enterpriseId,
      });
      setCourseSummary(response?.data?.attributes);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCourseProgress = async () => {
    const enterpriseId = profileInfo?.enterpriseID || '';
    const path = `/contentperformancefunnel/${courseId}`;
    try {
      const response = await fetchMetrics(path, {
        enterpriseId,
      });
      prepareChartData(response?.data?.attributes);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCourseInfo = async () => {
    try {
      if (courseId) {
        const course = await fetchCourseById(courseId);
        setCourseData(course);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const navigateToContentTab = () => {
    navigate('/admin/performance/content/');
  };

  useEffect(() => {
    if (profileInfo?.id && courseId) {
      fetchCourseInfo();
      fetchCourseReportForEnrolledUsers();
      if (!isPrivateCourse) {
        fetchCourseProgress();
        fetchCourseRanking();
      }
    }
  }, [profileInfo?.id, courseId]);

  const getEnrollmentColumns = () => {
    const columns = [];
    columns.push({
      title: <span className={styles.tableTitle}>Name</span>,
      dataIndex: 'name',
      key: 'name',
      render: (text:string) => (
        <div className={styles.nameButton}>
          {text}
        </div>
      ),
    });
    if (!isPrivateCourse) {
      columns.push({
        title: <span className={styles.tableTitle}>Classes</span>,
        dataIndex: 'classes',
        key: 'classes',
      }, {
        title: <span className={styles.tableTitle}>Assignments</span>,
        dataIndex: 'assignments',
        key: 'assignments',
      });
    }
    columns.push({
      title: <span className={styles.tableTitle}>Completion</span>,
      dataIndex: 'completion',
      key: 'completion',
      defaultSortOrder: 'descend',
      width: 300,
      sorter: (a:CourseProgress, b:CourseProgress) => Number(a.completion) - Number(b.completion),
      render: (value:number) => (
        <Progress
          percent={Math.ceil(value)}
          trailColor="#f0f4f7"
          strokeColor="#a4ede2"
          width={150}
        />
      ),
    });
    return columns;
  };

  const enrollmentColumns = getEnrollmentColumns();

  const headers = enrollmentColumns
    .map((column) => ({ label: column.dataIndex, key: column.key }));

  return (
    <div className={styles.courseReportContainer}>
      <div className={styles.headingContainer}>
        <div className={styles.alignmentContainer}>
          <button
            id="admin-performance-content-course-report-navigate-back"
            type="submit"
            onClick={navigateToContentTab}
            className={`library-shaw-icon-new ${styles.backArrowButton}`}
          >
            <i className="icon-new-Left-Arrow-6" />
          </button>
          <div>
            {!isPrivateCourse
            && <div className={styles.degreeName}>Diploma in</div>}
            <div className={styles.degreeValue}>{courseData?.courseName}</div>
          </div>
        </div>
        {!isPrivateCourse
        && (
        <div style={{ flex: '0.8' }}>
          <div className={styles.categoryTitle}>Skill Category</div>
          <div className={styles.categoryName}>{courseData?.tags?.[0]}</div>
        </div>
        )}
      </div>
      {!isPrivateCourse && (
      <>
        <div className={styles.progressionContainer}>
          <div className={styles.progressionTitle}>
            Progression Funnel
          </div>
          {chartData
          && <BarChart chartData={chartData} />}
        </div>
        <div className={styles.rankSummaryContainer}>
          <div style={{ paddingLeft: '50px', flex: '3' }}>
            <div className={styles.rankValue}>
              #
              {courseSummary?.rank}
            </div>
            <div className={styles.rankTitle}>Course Rank Based on total activity</div>
          </div>
          <div style={{ flex: '1' }}>
            <div className={styles.rankValue}>
              {courseSummary?.usersWatchingLesson}
            </div>
            <div className={styles.rankTitle}>Total Lessons Watched</div>
          </div>
          <div style={{ flex: '1' }}>
            <div className={styles.rankValue}>
              {courseSummary?.usersCompletedAssignment}
            </div>
            <div className={styles.rankTitle}>Assignments Completed</div>
          </div>
        </div>
      </>
      )}
      <div className={styles.enrollContainer}>
        <div className={`${styles.degreeValue} ${isPrivateCourse ? styles.addPadding : ''}`}>Enrolled Learners</div>
      </div>
      <div className={styles.resultsAndPagesContainer}>
        <div>
          <div className={styles.resultTotal}>{`${tableData?.length} results`}</div>
          <div className={styles.learnerLink}>
            Click on the names to go to learner’s report page
          </div>
        </div>
      </div>
      <TableComponent
        globalSearchPlaceholder="Search by learner name"
        displayColumns={enrollmentColumns}
        allData={tableData}
        headers={headers}
        fileName="Course Report.csv"
        tableLoader={loader}
        domIDs={{
          Download: 'admin-performance-content-course-report-download',
        }}
      />
    </div>
  );
};
export default CourseReport;
