// extracted by mini-css-extract-plugin
export var headingContainer = "CourseReport-module--headingContainer--KaS2H";
export var courseReportContainer = "CourseReport-module--courseReportContainer--2MIpP";
export var degreeValue = "CourseReport-module--degreeValue--1kon_";
export var addPadding = "CourseReport-module--addPadding--2YgFY";
export var backArrowButton = "CourseReport-module--backArrowButton--VolAc";
export var dropdownMenu = "CourseReport-module--dropdownMenu--2ruBP";
export var eachMenuData = "CourseReport-module--eachMenuData--2xBor";
export var alignmentContainer = "CourseReport-module--alignmentContainer--28FYY";
export var degreeName = "CourseReport-module--degreeName--3mlC3";
export var courseName = "CourseReport-module--courseName--1du04";
export var categoryTitle = "CourseReport-module--categoryTitle--2-oSU";
export var categoryName = "CourseReport-module--categoryName--2WQy-";
export var progressionContainer = "CourseReport-module--progressionContainer--3fFAS";
export var progressionTitle = "CourseReport-module--progressionTitle--2zm_m";
export var progressionImage = "CourseReport-module--progressionImage--rPj_g";
export var rankSummaryContainer = "CourseReport-module--rankSummaryContainer--1ciyt";
export var rankValue = "CourseReport-module--rankValue--eap4u";
export var rankTitle = "CourseReport-module--rankTitle--36u2G";
export var enrollContainer = "CourseReport-module--enrollContainer--1rXkU";
export var searchInputContainer = "CourseReport-module--searchInputContainer--19Qc-";
export var searchIconContainer = "CourseReport-module--searchIconContainer--2zLkf";
export var searchInput = "CourseReport-module--searchInput--2SKPe";
export var resultTotal = "CourseReport-module--resultTotal--38ev9";
export var learnerLink = "CourseReport-module--learnerLink--3qcQ1";
export var paginationContainer = "CourseReport-module--paginationContainer--2BHOd";
export var paginationText = "CourseReport-module--paginationText--2iwVq";
export var durationFilter = "CourseReport-module--durationFilter--3VtCR";
export var currentPage = "CourseReport-module--currentPage--1Ogzc";
export var resultsAndPagesContainer = "CourseReport-module--resultsAndPagesContainer--V3Bsf";
export var nameButton = "CourseReport-module--nameButton--3U1Jv";
export var table = "CourseReport-module--table--1bUko";
export var dropDownArrow = "CourseReport-module--dropDownArrow--2hkFw";