import React from 'react';
import { Router } from '@reach/router';
import { AdminProvider } from '../../../context/Admin/AdminContext';
import CourseReport from './CourseReport';

const CourseReportWrapper:React.FC = () => (
  <AdminProvider>
    <Router basepath="/admin/performance/content/course-report">
      <CourseReport path="/:courseId" />
      <CourseReport default />
    </Router>
  </AdminProvider>
);

export default CourseReportWrapper;
