import React from 'react';
import Authenticate from '../../../../components/Identity/Authenticate';
import CourseReportWrapper from
  '../../../../components/Admin/Performance/CourseReportWrapper';
import * as styles from '../../../../styles/admin/Account.module.scss';

function UserReportView() {
  return (
    <div className={styles.accountPageWrapper}>
      <CourseReportWrapper />
    </div>
  );
}
export default Authenticate(UserReportView);
