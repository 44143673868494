/* eslint-disable no-await-in-loop */
import React, { useEffect, useState } from 'react';
import { Storage } from 'aws-amplify';
import {
  fetchUserBySub, listUsersForManager, listGroups,
} from '../../components/DataStore/Services';
import {
  Role, User, EnterpriseProfile,
} from '../../models/index';
import { getCurrentUser } from '../../components/Shared/Services/AuthService';
import {
  getEnterpriseProfile, getGroupMembersByGroupId, getGroupsByEnterpriseID, getUsersByEnterpriseID,
} from '../../components/GraphQL/Services';
import { EnterpriseUser } from '../../types/enterpriseUser';
import { GroupMember } from '../../types/groupMember';
import { isBDMUser } from '../../services/utils';

export type ProfileInfo = User & {
  _version: number,
  'custom:active_user': string,
}

export type EnterpriseInfo = EnterpriseProfile & {
  _version: number,
}

export type UserData = {
  role: string,
  email: string,
  id: string,
  sub: string,
  groups?: Array<any>
  usersAndGroupsForManagers?: {
    groups?: Array<any>,
    userList?: any,
  },
}

export enum MoreInfoStatus {
  DEFAULT,
  SECONDARY,
  PRIMARY
}

export type MappedEnterpriseUsersList = Map<string, EnterpriseUser>

export type MappedGroupMembersList = Map<string, {
  groupName: string,
  memberInfo:GroupMember[]
}>

export type AdminContextType = {
  profileInfo: ProfileInfo,
  setProfileInfo: (u: ProfileInfo) => void,
  companyInfo: EnterpriseInfo,
  setCompanyInfo: (u: EnterpriseInfo) => void,
  userData: UserData,
  fetchRoleBasedData: () => void,
  showMoreInfo: MoreInfoStatus,
  setShowMoreInfo: React.Dispatch<React.SetStateAction<MoreInfoStatus>>,
  enterpriseUsersList: MappedEnterpriseUsersList | null,
  setEnterpriseUsersList: (u: MappedEnterpriseUsersList) => void,
  mapOfGroupsList: MappedGroupMembersList | null,
  setMapOfGroupsList: (u: MappedGroupMembersList) => void,
  fetchGroupsList: (u: string) => void
}

const defaultState = {
  profileInfo: {} as ProfileInfo,
  setProfileInfo: () => {},
  companyInfo: {} as EnterpriseInfo,
  setCompanyInfo: () => {},
  userData: {} as UserData,
  fetchRoleBasedData: () => Promise,
  showMoreInfo: 0 as MoreInfoStatus,
  setShowMoreInfo: () => {},
  enterpriseUsersList: null,
  setEnterpriseUsersList: () => {},
  mapOfGroupsList: null,
  setMapOfGroupsList: () => {},
  fetchGroupsList: () => {},
};

const AdminContext = React.createContext<AdminContextType>(defaultState);

type Props = {
  children: React.ReactNode,
}

const AdminProvider:React.FC<Props> = (props) => {
  const { children } = props;
  const [profileInfo, setProfileInfo] = useState<ProfileInfo>({} as ProfileInfo);
  const [companyInfo, setCompanyInfo] = useState<EnterpriseInfo>({} as EnterpriseInfo);
  const [signedInUserData, setSignedInUserData] = useState<UserData>({} as UserData);
  const [showMoreInfo, setShowMoreInfo] = useState<MoreInfoStatus>(MoreInfoStatus.DEFAULT);
  const [
    enterpriseUsersList,
    setEnterpriseUsersList,
  ] = useState<MappedEnterpriseUsersList | null>(null);
  const [mapOfGroupsList, setMapOfGroupsList] = useState<MappedGroupMembersList | null>(null);

  /**
   * listing users when manager logs in
   *
   * @param {*} sub
   */
  const fetchUsersAndGroupsForManager = async (userRes:User) => {
    const sub = userRes?.sub;
    const id = userRes?.id;
    const responseList = await listUsersForManager(sub, id);
    return responseList;
  };

  const getActiveUserData = async () => {
    const currentUser = await getCurrentUser();
    const subId = currentUser.attributes.sub;
    const activeUserId = currentUser.attributes?.['custom:active_user'];
    const userRes = await fetchUserBySub(subId, activeUserId);
    return userRes;
  };

  const fetchRoleBasedData = async () => {
    const user = await getActiveUserData();
    let data = {};
    if (user?.roles.includes(Role.ADMIN) || user?.roles.includes(Role.OWNER)) {
      const groupData = await listGroups();
      data = {
        role: 'ADMIN',
        email: user.email,
        sub: user.sub,
        id: user.id,
        groups: groupData,
      };
    } else if (user?.roles.includes(Role.MANAGER)) {
      const usersAndGroupsForManagers = await fetchUsersAndGroupsForManager(user);
      data = {
        role: 'MANAGER',
        email: user.email,
        sub: user.sub,
        id: user.id,
        usersAndGroupsForManagers,
      };
    } else {
      data = {
        role: 'LEARNER',
        email: user.email,
        sub: user.sub,
        id: user.id,
      };
    }
    return data;
  };

  const fetchUsersList = async (companyDetails: EnterpriseInfo) => {
    const mapOfUsersList: any = new Map();
    const allUsersList = await getUsersByEnterpriseID(companyDetails?.id);
    const activeUsers = allUsersList?.filter((
      user,
    ) => !user?.deletedAt && user?.enabled && !isBDMUser(user.email));
    activeUsers?.forEach((user: EnterpriseUser) => {
      mapOfUsersList.set(user?.id, user);
    });

    setEnterpriseUsersList(mapOfUsersList);
  };

  const fetchGroupsList = async (enterpriseId: string) => {
    const mapOfGroups: any = new Map();
    const allGroupsList = await getGroupsByEnterpriseID(enterpriseId);

    const filteredGroups = allGroupsList.filter((group) => !group?.deletedAt);

    const arrayOfGroupIdAndName = filteredGroups.map((group) => ({
      groupId: group?.id,
      groupName: group?.groupName,
    }));

    for (let i = 0; i < arrayOfGroupIdAndName?.length; i += 1) {
      const groupInfo = await getGroupMembersByGroupId(arrayOfGroupIdAndName[i]?.groupId);

      mapOfGroups.set(arrayOfGroupIdAndName[i]?.groupId,
        {
          memberInfo: groupInfo,
          groupName: arrayOfGroupIdAndName[i]?.groupName,
        });
    }

    setMapOfGroupsList(mapOfGroups);
  };

  const fetchCompanyInfo = async (user:User) => {
    const enterpriseId = user?.enterpriseID;
    const companyProfileInfo = await getEnterpriseProfile(enterpriseId);
    fetchUsersList(companyProfileInfo);
    fetchGroupsList(companyProfileInfo?.id);
    setCompanyInfo(companyProfileInfo);
  };

  /**
   *
   *
   */
  const loadDependencies = async () => {
    const userRes = await getActiveUserData();
    fetchCompanyInfo(userRes);
    const data = await fetchRoleBasedData() as UserData;
    setSignedInUserData(data);
    try {
      const profilePicture = await Storage.get(userRes.id);
      setProfileInfo({
        ...userRes,
        ...profilePicture && {
          profilePicture,
        },
      });
    } catch {
      setProfileInfo(userRes);
    }
  };

  useEffect(() => {
    loadDependencies();
  }, []);

  return (
    <AdminContext.Provider
      value={{
        profileInfo,
        setProfileInfo,
        fetchRoleBasedData,
        userData: signedInUserData,
        companyInfo,
        setCompanyInfo,
        showMoreInfo,
        setShowMoreInfo,
        enterpriseUsersList,
        setEnterpriseUsersList,
        mapOfGroupsList,
        setMapOfGroupsList,
        fetchGroupsList,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export default AdminContext;

export { AdminProvider };
